import React from 'react'
import { useSelector } from "react-redux";

const TopAanbiedingen = () => {

    const { folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section id="topaanbiedingen">
                <div className='container'>
                    <figure><a href='https://addelhaizelebbeke.be/?lang=nl#DigitalDisplay' target="_blank"><img src={`https://o-sn.be/kml/test-onepages/${folder_name}/thumb.jpg`}/></a></figure>
                    <figure><img src={`https://o-sn.be/kml/test-onepages/${folder_name}/topaanbiedingen.jpg`}/></figure>
                </div>
        </section>
    )
}

export default TopAanbiedingen