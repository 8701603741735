import Icon from '@identitybuilding/idb-react-iconlib';
import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const Traiteur = () => {

    const { lang, translate, folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section id="traiteur">
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Convergence&display=swap');
            </style>
            <h3>Onze eigen Traiteur afdeling</h3>
            <p className='intro'>AD Lebbeke biedt een oplossing voor elke gelegenheid en in iedere hoeveelheid: <br />
                van warme bereidingen in ‘microgolf-gelaste’ verpakkingen tot koude schotels van elk formaat.</p>


            <div className='temp-folder'>
                <p className='intro'>Bekijk onze <b>eindejaarsfolder!</b></p>
                <a href='https://booklet.cataloguenetwork.be/111/nl/index-h5.html#page=1' target='_blank'>
                    <img src="https://zappa-tlaqv351d.s3.amazonaws.com/media/cmps/cn111_nl_page1.jpg" />
                    <p className='intro cmp'>01-12-2023 <Icon name="Magazines" /> 03-01-2024</p>
                </a>
            </div>

            <div className='container'>
                <aside>
                    <h4>DAGELIJKS BESCHIKBAAR IN PORTIES VAN CA. 400 GRAM <br />- UIT EIGEN KEUKEN</h4>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Rundstong in madeirasaus</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€19,45/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Rundsstoofvlees</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€16,95/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Spaghetti saus</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€12,90/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Spaghetti bereid</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€10,90/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Balletjes in tomatensaus</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€13,45/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Vol-au-vent v/h huis ‘extra’</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€15,90/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Paardenworst in biersaus</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€15,45/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <h4>BELEGDE BROODJES</h4>
                    <p className='item-name'>Broodjes op <b>weekdagen tot 17u</b>, op <b>zaterdag tot 16u</b>. </p>
                    <p className='item-name'>Onze broodjes zijn witte halve stokbroden (+/- 27 cm). </p>
                    <p className='item-name'>Ook verkrijgbaar op half meergranenstokbrood <b>(+ 0,20 €)</b> of bruine Sun Bun <b>(+0,50 €)</b></p>
                    <p className='item-name'>Broodjes zonder groenten <b>(- 0,50 €)</b></p>
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Pistolet of piccolo gewoon beleg</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€1.80</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Pistolet of piccolo smos beleg</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€2.30</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Half stokbrood + beleg (zonder groenten)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€2.80</span>
                        </div>
                    </div> */}
                    {/* --------------------------------------------------------------------- */}
                    {/* <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Half stokbrood met smos beleg (in micablister + € 0,30)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€3.40</span>
                        </div>
                    </div> */}
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Smos kaas en hesp</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.10</span>
                        </div>
                        <p>(hesp, kaas, ei, tomaat, wortel, sla en mayonaise)</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Smos kaas</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€3.60</span>
                        </div>
                        <p>(kaas, ei, tomaat, wortel, sla en mayonaise)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Smos hesp</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€3.80</span>
                        </div>
                        <p>(hesp, ei, tomaat, wortel, sla en mayonaise)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Smos américain préparé</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.00</span>
                        </div>
                        <p>(huisbereide américain préparé, ei, tomaat, wortel, sla en mayonaise)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Martino</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.10</span>
                        </div>
                        <p>(huisbereide américain préparé, martinosaus, ui, augurkjes en sla)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Club Hawai</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.30</span>
                        </div>
                        <p>(hesp, kaas, ananas, ei, tomaat, wortel, sla en cocktailsaus)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Pollito</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€3.80</span>
                        </div>
                        <p>(kippewit, ei, tomaat, wortel, sla en mayonaise)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Bolero</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.10</span>
                        </div>
                        <p>(kip curry, ananas, sla)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Smos rosbief</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.60</span>
                        </div>
                        <p>(rosbief, ei, tomaat, wortel, sla en mayonaise)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Boulette</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€3.80</span>
                        </div>
                        <p>(Balletje v/h huis, ketchup (curry of tomaat))</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Boulette spéciale</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.00</span>
                        </div>
                        <p>(Balletje v/h huis, ui, mayonaise, ketchup (curry of tomaat))</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Smos vleessla</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€3.80</span>
                        </div>
                        <p>(huisbereide vleessla met ei, tomaat, wortel en sla)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Smos rauwe ham</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.70</span>
                        </div>
                        <p>(Rauwe ham, ei, tomaat, wortel, sla en mayonaise)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Broodje gerookte zalmsla</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.40</span>
                        </div>
                        <p>(huisbereide gerookte zalmsla, tomaat, sla)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Broodje krabsla</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.50</span>
                        </div>
                        <p>(huisbereide krabsla (krab en surimi), tomaat en sla)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Broodje garnaalsla</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€5.30</span>
                        </div>
                        <p>(huisbereide grijze garnaalsla, tomaat en sla)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Broodje tonijnsla</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.40</span>
                        </div>
                        <p>(tonijncocktailsla, ei, tomaat, wortel en sla)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Tonijntino</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.50</span>
                        </div>
                        <p>(tonijncocktailsla, ui, augurkjes, sla en martinosaus)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Broodje brie</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4.30</span>
                        </div>
                        <p>(brie, honing en sla)</p>

                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <h4>BREUGELIAANS VERRASSINGSBROOD <br /> (FEESTELIJK OPGEMAAKT OP DRAAGPLANK):</h4>
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Brood 40 cm met 48 mini-sandwichen</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€75</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Brood 40 cm met 60 mini-sandwichen</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€85</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Brood 60 cm met 80 mini-sandwichen</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€110</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Fruitgarnituur (per brood, per plank)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€18</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                </aside>
                <aside>
                    {/* --------------------------------------------------------------------- */}
                    <h4>BUFFETTEN</h4>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Charcuterieschotel</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€14,40/persoon</span>
                        </div>
                        <p>geschikte schotel met assortiment fijne vleeswaren</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Koude vleesschotel</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€15,95/persoon</span>
                        </div>
                        <p>hamrolletje, kippenfilet, parma met meloen, varkensgebraad, gebakken rosbief + ½ tomaat-garnaal</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gekookte roze zalm, zonder garnituur</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>dagprijs/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gegarneerde zalm met gevulde eitjes vanaf 6 personen</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€12,50/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gegarneerde zalm met gevulde eitjes</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€65,00 / 8 personen</span>
                        </div>
                    </div> */}
                    {/* --------------------------------------------------------------------- */}
                    {/* <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gegarneerde zalm met gevulde eitjes</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€72,50 / 10 personen</span>
                        </div>
                    </div> */}
                    {/* --------------------------------------------------------------------- */}
                    {/* <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gegarneerde zalm met gevulde eitjes</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€80,00 / 12 personen</span>
                        </div>
                    </div> */}
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Halve tomaat grijze garnaal (in supplement)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€6</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Koude visschotel</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€22,00/persoon</span>
                        </div>
                        <p>gerookt visassortiment en ½ tomaat-garnaal, zalmfilet</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Volledig koud buffet</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€28,00/persoon</span>
                        </div>
                        <p>met roze zalm, gerookte vis, koude vleesschotel, aardappelen, pasta, koude groenten en sausjes</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Breugelbuffet ‘Vlaamse wijze’ vanaf 6 personen</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€14,00/persoon</span>
                        </div>
                        <p>fricandon, pens, kop, paté, hesp en ham, droge worst, gebakken balletjes,jonge kaas, appelmoes, krieken, rijstpap, platte kaas (groepstarief)</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Tapasplank als hapjesschotel (min. 6 personen)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€10,90/persoon</span>
                        </div>
                        <p>schaaltjes schotel met oa. zuiderse tomaat, chorizo, kaas, tomatare, gerookte ham, salami, gevulde pepertjes, panchetta, ansjovis, olijvenassortiment en grissini</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Tapasplank als hoofdgerecht (min. 6 personen)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€14,00/persoon</span>
                        </div>
                        <p>schaaltjes schotel met oa. zuiderse tomaat, chorizo, kaas, tomatare, gerookte ham, salami, gevulde pepertjes, panchetta, ansjovis, olijvenassortiment en grissini</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <h4>KOUDE SCHOTELS IN MEENEEMSCHAAL</h4>
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Salade niçoise: tonijn, ansjovis, groenten</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€8,50/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Hamrolletjes met asperges en garnituur</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€8/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Tomaat met grijze garnalen en garnituur</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€9,90/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Perzik met tonijnsla en groenten</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€8/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gerookte zalmsneden met zalmsla</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€9,90/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <h4>APERITIEF OF HAPJE</h4>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Aperitiefhapjes</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€1,80/stuk</span>
                        </div>
                        <p>delicatesse assortiment lepel- en bekerhapjes</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Kleine belegde toastjes</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€0,50/stuk</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Bierplank met hesp, salami, kop, mosterd</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4,50/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Receptiebord van 6 tot 8 personen</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€22,00/per bord</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Receptiemand: belegde mini-sandwichen</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€1,10/sandwich</span>
                        </div>
                        <p>met groenten €1,40/sandwich</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Receptiemand: belegde mini-pistolets</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€1,40/pistolet</span>
                        </div>
                        <p>met groenten €1,60/pistolet</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <h4>GOURMET, FONDUE EN STEENGRILL</h4>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Gourmet, 8 vleessoorten in meeneemschotel</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€21,00/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Fondue, 5 vleessoorten in meeneemschotel</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€21,00/kg</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Groenten fondue/barbecue</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4,90</span>
                        </div>
                        <p>koude aardappelen, pasta & koude groenten mayonaise & cocktailsaus</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Groenten gourmet</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€5,40</span>
                        </div>
                        <p>idem groenten fondue+bakaardappeltjes, champignons</p>
                    </div>
                    {/* --------------------------------------------------------------------- */}

                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <h4>KAASSCHOTELS (MET SALADE EN FRUITGARNITUUR)</h4>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Als hoofdgerecht (280gr kaas/persoon)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€14,50/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Als dessert (180gr kaas/persoon)</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€12,50/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <h4>EINDEJAARSBEREIDINGEN</h4>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Speciale vis-, wild- en gevogelteschotels</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>zie folders</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------- */}
                    <h4>VARIA</h4>
                    {/* --------------------------------------------------------------------- */}
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Kinderparty schotel: kippenwit, kaasjes, balletjes</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€8,50/persoon</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Kindersnoep schotel</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€6,50/persoon</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Supplement voor halve tomaat- grijze garnalen</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€6,00/stuk</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='top-item'>
                            <span className='item-name'>Koude aardappelen, pasta en koude groenten</span>
                            <span className='item-strip'></span>
                            <span className='item-price'>€4,25/persoon</span>
                        </div>
                    </div>
                    {/* --------------------------------------------------------------------- */}
                </aside>
            </div>
            <div className='info'>
                <h4>Speciale wensen?</h4>
                <p>
                    Hebt U speciale wensen voor uw feesten? <br />
                    Spreek er ons over, wij helpen U zeker verder!
                </p>

                <h4>Voorwaarde!</h4>
                <div>
                    <ul>
                        <li>Prijzen gelden voor afgehaalde schotels.</li>
                        <li>Thuislevering binnen Lebbeke kost € 8, voor de randgemeenten kost het € 10.</li>
                        <li>Thuislevering buiten Lebbeke is mogelijk, prijs: € 0,20/km met start forfait van € 10.</li>
                        <li>Groepstarief geldig vanaf 20 personen: 10% op de schotels behalve voor aperitiefhapjes, aperitiefschotel en verrassingsbrood.</li>
                    </ul>
                    <ul>
                        <li>Voor uw feesten geven wij bij bestelling van wijnen en bieren gratis wijn- en bierglazen in bruikleen.</li>
                        <li>Traiteurbestellingen nemen niet deel aan andere promotieacties.</li>
                        <li>Geef uw bestellingen minimum 4 dagen op voorhand door. Voor vakantie, communie-en eindejaarsperiode: minimum één week.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Traiteur